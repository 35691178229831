(function() {

    var browserLang = {};
    var siteLang = {};
    var defaultLang = false;

    var options = {
        siteLang: '',
        langs: [],
        delay: 1000
    };

    var translations = {"cz":{"name":"Česká verze","txt":"Přepnout do Česky","txt2":"Pokračujeme Česky","txt3":"Vítejte!","welcome":"Vítejte!"},"de":{"name":"Deutsche Version","txt":"auf Deutsch umschalten","txt2":"weiterhin in Deutsch","txt3":"Sprechen Sie Deutsch?","welcome":"Willkommen!"},"en":{"name":"English version","txt":"Switch to english","txt2":"Continue in english","txt3":"Is your language English ?","welcome":"Welcome!"},"es":{"name":"Versión española","txt":"Cambiar a español","txt2":"Continua en español","txt3":"?Hablas español ? ","welcome":"Bienvenido!"},"fr":{"name":"Version française","txt":"Passer au français","txt2":"Continuer en français","txt3":"Continuer en français","welcome":"Bienvenue!"},"global":{"name":"{lang} version","txt":"Switch to {lang}","txt2":"Continue in {lang}","txt3":"Continue in {lang}","welcome":""},"it":{"name":"Versione italiana","txt":"Cambia in italiano","txt2":"Continua in italiano","txt3":"Parli Italiano?","welcome":"Benvenuti!"},"no":{"name":"Engelsk versjon","txt":"Bytt til engelsk","txt2":"Fortsett på engelsk","welcome":"Velkommen!"},"pl":{"name":"Wersja polska","txt":"Zmień na Polski","txt2":"Kontynuuj w języku polskim","txt3":"Czy Twój język to Polski ? ","welcome":"Witaj!"},"ro":{"name":"Versiunea românească","txt":"Schimbă in Română","txt2":"Continuă în Română","txt3":"Continuă în Română","welcome":"Bine ați venit!"},"ru":{"name":"Русская версия","txt":"Продолжить на русском языке","txt2":"продолжать на русском","txt3":"продолжать на русском","welcome":"добро пожаловать!"},"sk":{"name":"Slovenská verzia","txt":"Prepnúť do slovenčiny","txt2":"Pokračujeme slovenský","txt3":"Pokračujeme slovenský","welcome":"Vitajte!"},"sl":{"name":"Angleška različica","txt":"Preklopite na angleški jezik","txt2":"Nadaljujte v angleščini","welcome":"Dobrodošli!"}};

    var template = '<div class="lang-modal">' +
                        '<button type="button" class="lang-modal__close" aria-label="Close" data-dismiss="modal"></button>' +
                        '<div class="lang-modal__inner">' +
                            '<header class="lang-modal__header">' +
                                '<h2 class="lang-modal__header__title"></h2>' +
                                '<h3 class="lang-modal__header__txt"></h3>' +
                            '</header>' +
                            '<div class="lang-modal__current">' +
                                '<a class="lang-current lang-current--browser" href="#">' +
                                    '<span class="lang-current__inner">' +
                                        '<span class="lang-flag"><i></i></span>' +
                                        '<span class="lang-current__content">' +
                                            '<span class="lang-current__name"></span>' +
                                            '<span class="lang-current__txt"></span>' +
                                        '</span>' +
                                    '</span>' +
                                '</a>' +
                                '<a class="lang-current lang-current--site" href="#">' +
                                    '<span class="lang-flag"><i></i></span>' +
                                    '<span class="lang-current__name"></span>' +
                                    '<span class="lang-current__txt"></span>' +
                                '</a>' +
                            '</div>' +
                            '<div class="lang-modal__list">' +
                                '<ul></ul>' +
                            '</div>' +
                        '</div>' +
                    '</div>';

    var getTranslation = function(lang, trans) {
        var translation = translations[lang['key']];

        if(translation && translation[trans]) {
            return translation[trans];
        } else {
            return translations.global[trans].replace('{lang}', lang['name']);
        }
    };

    var $modal = null;

    var modal = {
        /**
         * Init events
         */
        init: function () {

            if(window.LangTranslations) {
                translations = $.extend({}, translations, LangTranslations);
            }

            $modal = $('<div/>', {
                'id': 'lang-modal',
                'class': 'lang-modal-hld',
                'html': template
            });

            var $langCurrentBrowser = $modal.find('.lang-current--browser');
            var $langCurrentSite = $modal.find('.lang-current--site');
            var $langListHld = $modal.find('.lang-modal__list ul');
            var welcome = getTranslation(siteLang, 'welcome') + ' ';

            /**
             * If current browsers language
             * is not available on site
             * show english as default switch
             *
             * Else -> show 'continue in current browsers language'
             *
             */
            if(!browserLang.name) {

                if(defaultLang) {
                    $langCurrentBrowser.remove();

                    $langCurrentSite.find('.lang-current__name').html(getTranslation(defaultLang, 'name'));
                    $langCurrentSite.find('.lang-current__txt').html(getTranslation(defaultLang, 'txt'));
                    $langCurrentSite.find('.lang-flag i').addClass(defaultLang.key);
                    $langCurrentSite.attr('href', defaultLang.url);
                    $langCurrentSite.on('click', function(e){
                        createCookie('lang', defaultLang.key, 1);
                    });

                    $modal.find('.lang-modal__header__title').html(getTranslation(defaultLang, 'welcome'));
                } else {
                    $modal.find('.lang-modal__current').remove();
                }
            } else {
                $langCurrentSite.remove();
                $langCurrentBrowser.find('.lang-current__name').html(getTranslation(browserLang, 'name'));
                $langCurrentBrowser.find('.lang-current__txt').html(getTranslation(browserLang, 'txt'));
                $langCurrentBrowser.find('.lang-flag i').addClass(browserLang.key);
                $langCurrentBrowser.attr('href', browserLang.url);
                $langCurrentBrowser.on('click', function(e){
                    createCookie('lang', browserLang.key, 1);
                });

                $modal.find('.lang-modal__header__title').html(getTranslation(browserLang, 'welcome'));
            }

            /**
             * Show Site default language as first
             * on the list of available languages
             */
            if(siteLang.name) {
                $langListHld.append(
                    '<li>' +
                        '<a href="' + siteLang.url + '" data-lang="' + siteLang.key + '">' +
                            '<span class="lang-flag"><i class="' + siteLang.key + '"></i></span>' +
                            '<span>' + getTranslation(siteLang, 'name') + '</span>' +
                        '</a>' +
                    '</li>'
                ).find('a').on('click', function(e){
                    e.preventDefault();
                    createCookie('lang', siteLang.key, 1);
                    modal.hide();
                });
            }

            /**
             * Show rest of languages
             */
            $.each(options.langs, function(i, lang){
                $langListHld.append(
                    '<li>' +
                        '<a href="' + lang.url + '" data-lang="' + lang.key + '">' +
                            '<span class="lang-flag"><i class="' + lang.key + '"></i></span>' +
                            '<span>' + getTranslation(lang, 'name') + '</span>' +
                        '</a>' +
                    '</li>'
                );

                welcome += getTranslation(lang, 'welcome') + ' ';
            });

            $langListHld.find('a').on('click', function(){
                var lang = $(this).attr('data-lang');
                createCookie('lang', lang, 1);
            });

            $modal.find('.lang-modal__header__txt').html(welcome);
            $modal.appendTo('body');

            $modal.find('.lang-modal__list a').on('click', function(e){
                var lang = $(this).attr('data-lang');

                createCookie('lang', lang, 1);
            });

            // Close modal clicking in button X
            $('.lang-modal__close').on('click', function(e){
                modal.hide();
                createCookie('lang', siteLang['key'], 1);
            })

            // Close modal clicking in bg
            $('body').on('click', '.lang-modal-hld', function(e){
                if($(e.target).is('.lang-modal-hld')) {
                    modal.hide();
                }
            })

            // Show modal
            modal.show();
        },
        show: function () {
            $modal.show(0).addClass('active');
        },
        hide: function () {
            $modal.removeClass('active');
            setTimeout(function(){
                $modal.hide(0);
            }, 300);
        }
    };

    var checkLang = function(language) {
        if(language.key !== options.siteLang && !readCookie('lang')) {
            modal.init();
        }
    };

    var getLang = function() {
        return browserLang;
    };

    var init = function(settings) {
        options = $.extend({}, options, settings);


        if(window.LangOptions) {
            options = $.extend({}, options, window.LangOptions);
        }

        siteLang = {
            key: options.siteLang,
            url: '',
            name: ''
        };

        var lang = window.navigator.languages ? window.navigator.languages[0] : null;
            lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

        if (lang.indexOf('-') !== -1) {
            lang = lang.split('-')[0];
        }

        if (lang.indexOf('_') !== -1) {
            lang = lang.split('_')[0];
        }

        browserLang = {
            key: lang,
            url: '',
            name: ''
        };

        $.each(options.langs, function(i, lang) {
            if(lang.key == browserLang.key) {
                browserLang.url = lang.url;
                browserLang.name = lang.name;
            }

            if(lang.key == siteLang.key) {
                siteLang.name = lang.name;
            }

            if(lang.key == 'en') {
                defaultLang = lang;
            }
        });

        if(!browserLang.name) {
            options.langs = options.langs.filter(function(el) {
                return (el.key !== options.siteLang && el.key !== 'en');
            });
        } else {
            options.langs = options.langs.filter(function(el) {
                return (el.key !== browserLang.key) && (el.key !== options.siteLang);
            });
        }

        setTimeout(function(){
            checkLang(browserLang);
        }, options.delay);

    }

    var api = {
        init: init,
        getLang: getLang,
        modal: modal
    };

    /**
    * Expose as a global
    */

    window.Lang = api;

    /**
     * Cookies helper functions
     */

    // Create cookie
    function createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name+"="+value+expires+"; path=/";
    }

    // Read cookie
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1,c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length,c.length);
            }
        }
        return null;
    }

})();
